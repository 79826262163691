import affichearticle614 from "./cards/AFFICHE ARTICLE-45.jpg"

function Grade614() {
    return (<>
        <h1> Task Cards - Fractions & Operations </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of fractions and operations with 6th grade students.</p>
        <img src={affichearticle614}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%206TH%20GRADE%20-%20FRACTIONS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade614