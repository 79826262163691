import affichearticle18 from "./cards/AFFICHE ARTICLE-9.jpg"

function MiddleSchool18() {
    return (<>
        <h1> Chess Activity #2 </h1>
        <p> Hello everyone,</p>

        <p>I share with you the continuation of the activity "Chess Activities #1". In this new activity, students learn to apply the rules by playing games.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle18}/>
        <a href="/assets/MY%20CHESS%20ACTIVITIES%20%232.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool18