import affichearticle65 from "./cards/AFFICHE ARTICLE-69.jpg"
import affichearticle62 from "./cards/AFFICHE ARTICLE-65.jpg";

function Grade65() {
    return (<>
        <h1> Robot Multiplication </h1>
        <p> Hello everyone,</p>

        <p>I share with you a a game to review the multiplication tables. This is a game that I give to students during the first week of class.</p>
        <p>The game includes game aids and rules.</p>
        <img src={affichearticle62}/>
        <p>I share the PDF.</p>
        <a href="/assets/ROBOT%20MULTIPLICATION%206TH%20GRADE.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade65