import LayoutArticles from "./LayoutArticles";
import {React} from "react";

const Digital = () => {
    return (<>
            <h1>Digital</h1>
            <LayoutArticles section={'Digital'} subsection={'Home'}/>
        </>
    );
};
export default Digital;
