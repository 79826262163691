import affichearticle14 from "./cards/AFFICHE ARTICLE-6.jpg"

function MiddleSchool14() {
    return (<>
        <h1> The Last Week - Day #4 </h1>
        <p> Hello everyone,</p>

        <p>This week, I'm sharing with you the 5 activities I do with my students during the last week of school.</p>
        <p>DAY #4 : Riddles book</p>
        <p>In this activity, students must solve mathematical riddles by team.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle14}/>
        <a href="/assets/Riddles%20book.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool14