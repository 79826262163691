import affichearticle20 from "./cards/AFFICHE ARTICLE-12.jpg"

function MiddleSchool20() {
    return (<>
        <h1> Commercial Game </h1>
        <p> Hello everyone,</p>

        <p>I share with you the list of commercial games that I use in class to work on mathematical logic.</p>
        <p>I invite you to click on the links of the games that interest you !</p>
        <img src={affichearticle20}/>
        <p>ASTEROID ESCAPE</p>
        <a href="https://www.amazon.com/Smart-Games-Asteroid-Escape-Challenges/dp/B07CLP4ZQC/ref=sr_1_29?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408539&sprefix=smart+game%2Caps%2C172&sr=8-29" target="_blank">Click Here !</a>
        <p>RUSH HOUR</p>
        <a href="https://www.amazon.com/ThinkFun-Rush-Traffic-Logic-Girls/dp/B00000DMER/ref=sr_1_36?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408539&sprefix=smart+game%2Caps%2C172&sr=8-36" target="_blank">Click Here !</a>
        <p>QUADRILLION</p>
        <a href="https://www.amazon.com/Smart-Quadrillion-Magnetic-Personal-Brain-Teasing-Family/dp/B00IZ0G3RQ/ref=sr_1_39?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408539&sprefix=smart+game%2Caps%2C172&sr=8-39" target="_blank">Click Here !</a>
        <p>IQ STARS</p>
        <a href="https://www.amazon.com/SmartGames-Stars-Travel-Challenges-Adult/dp/B07C8Q5FJ5/ref=sr_1_64?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408794&sprefix=smart+game%2Caps%2C172&sr=8-64" target="_blank">Click Here !</a>
        <p>METAFORMS</p>
        <a href="https://www.amazon.com/FoxMind-Games-MetaForms-Deductive-Complexity/dp/B0015MC2TO/ref=sr_1_69?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408794&sprefix=smart+game%2Caps%2C172&sr=8-69" target="_blank">Click Here !</a>
        <p>IQ LINK</p>
        <a href="https://www.amazon.com/SmartGames-Game-Bundle-3-Pack-Travel-Friendly/dp/B084H12QM2/ref=sr_1_9?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408990&sprefix=smart+game%2Caps%2C172&sr=8-9" target="_blank">Click Here !</a>
        <p>PLUG & PLAY</p>
        <a href="https://www.amazon.com/Smart-Games-Puzzler-Player-Challenges/dp/B0BH4VH2H5/ref=sr_1_21?crid=37R47CPE8IQOS&keywords=smart+game&qid=1674408990&sprefix=smart+game%2Caps%2C172&sr=8-21" target="_blank">Click Here !</a>
        <p>ALICE'S GARDEN</p>
        <a href="https://www.amazon.com/Goliath-Alices-Garden-Easy-Learn/dp/B0BV4DCTKF/ref=sr_1_8?crid=3QKL9G4RHAEOS&keywords=alice%27s+garden&qid=1685903184&sprefix=alice%27s+garden%2Caps%2C180&sr=8-8" target="_blank">Click Here !</a>
        <p>BREAK THE CODE</p>
        <a href="https://www.amazon.com/IELLO-BREAK_THE_CODE-Break-The-Code/dp/B07ZWKDN1R/ref=sr_1_1?crid=195G28RFH2YW5&keywords=break+the+code+game&qid=1685903511&sprefix=break+the+code+game%2Caps%2C175&sr=8-1" target="_blank">Click Here !</a>
        <p>DR. EUREKA</p>
        <a href="https://www.amazon.com/Blue-Orange-Eureka-Speed-Logic/dp/B01CKI02RU/ref=sr_1_5?crid=2DBJ19WPD8M7M&keywords=dr+eureka+game&qid=1685903552&sprefix=Dr+eureka%2Caps%2C183&sr=8-5" target="_blank">Click Here !</a>
        <p>BATTLESHIP</p>
        <a href="https://www.amazon.com/Hasbro-Gaming-Battleship-Classic-Strategy/dp/B09D4QRJ8Y/ref=sr_1_1?crid=1C64XEDCLVKHD&keywords=battle+ship+game+junior&qid=1685903710&sprefix=battle+ship+game+junior%2Caps%2C175&sr=8-1" target="_blank">Click Here !</a>
    </>)
}

export default MiddleSchool20