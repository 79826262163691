import affichearticle6 from "./cards/AFFICHE ARTICLE-2.png"

function MiddleSchool6() {
    return (<>
        <h1> Christmas Tangram </h1>
        <p> Hello everyone,</p>

        <p>Still not sure what to do as winter vacation approaches ?</p>
        <p>here is the folder with the Christmas Tangrams !</p>
        <p> To challenge your students with these 10 designs.</p>
        <p> Help and correction are available.</p>
        <p>The file contains 10 tangrams with the correction and is FREE.</p>
        <img src={affichearticle6}/>
        <a href="/assets/MY%20CHRISTMAS%20TANGRAM%20.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool6