import affichearticle9 from "./cards/AFFICHE ARTICLE-4.png"

function MiddleSchool9() {
    return (<>
        <h1> Easter Notebook </h1>
        <p> Hello everyone,</p>

        <p>Still not sure what to do as spring break approaches ?</p>
        <p>Here is the file with the Easter activities !</p>
        <p>a geometric construction activity to find out who batman's friend is.</p>
        <p>6 activities on calculating the area of complex figures.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle9}/>
        <a href="/assets/MY%20EASTER%20NOTEBOOK.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool9