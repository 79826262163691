import affichearticle68 from "./cards/AFFICHE ARTICLE-72.jpg"
import affichearticle62 from "./cards/AFFICHE ARTICLE-65.jpg";

function Grade68() {
    return (<>
        <h1> Maxim's Room Battle </h1>
        <p> Hello everyone,</p>

        <p>I share with you a a game to review the whole numbers. This is a game that I give to students during the first week of class.</p>
        <p>The game includes game aids and rules.</p>
        <img src={affichearticle62}/>
        <p>I share the PDF.</p>
        <a href="/assets/MAXIME'S%20ROOM%20BATTLE%206TH%20GRADE-2.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade68