import affichearticle from "./cards/AFFICHE ARTICLE-49.jpg"

function Grade618() {
    return (<>
        <h1> Task Cards - Area & Volume </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of area and volume with 6th grade students.</p>
        <img src={affichearticle}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%206TH%20GRADE%20-%20Area%20&%20Volume.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade618