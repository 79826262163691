import affichearticle611 from "./cards/AFFICHE ARTICLE-42.jpg"

function Grade611() {
    return (<>
        <h1> Task Cards - Order Of Operations </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the order of operations with 6th grade students.</p>
        <img src={affichearticle611}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%206TH%20GRADE%20-%20ORDER%20OF%20OPERATIONS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade611