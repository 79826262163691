import affichearticle17 from "./cards/AFFICHE ARTICLE-13.jpg"

function MiddleSchool17() {
    return (<>
        <h1> Chess Activity #1 </h1>
        <p> Hello everyone,</p>

        <p>I share with you this activity that will allow your students to discover chess games while having fun.</p>
        <p>They will first discover the chessboard, the different pieces and the rules of the game.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle17}/>
        <a href="/assets/MY%20CHESS%20ACTIVITIES%20%231.pdf" download>Download the PDF !</a>
        <h1> WHERE TO BUY ? </h1>
        <p>Personally, I buy my chess sets for my students on Amazon. It is easily transportable and storable because it is small and the parts are magnetized.</p>
        <a href="https://www.amazon.com/Magnetic-KAILE-Travel-Folding-Instructions/dp/B075NJFKX5/ref=sr_1_11?crid=NV4URJXVAT9F&keywords=chess%2Bgame&qid=1674409316&sprefix=cheese%2Bgame%2Caps%2C184&sr=8-11&th=1" target="_blank">Click Here !</a>
        <h1> ONLINE TUTORIAL ? </h1>
        <p>In class, I use the "ChessKid" tutorial videos. The videos are clear and explain the rules in a very simple way. Students always understand! The animations are pretty and engaging.
            The videos are short and cover all the information needed to start playing chess.</p>
        <a href="https://www.youtube.com/@ChessKidOfficial" target="_blank">Click Here !</a>
    </>)
}

export default MiddleSchool17