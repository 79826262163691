import affichearticle from "./cards/AFFICHE ARTICLE-53.jpg"

function Grade74() {
    return (<>
        <h1>Task Cards - Operations Integers </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of operations and integers with 7th grade students.</p>
        <img src={affichearticle}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%207TH%20GRADE%20OPERATIONS%20INTEGERS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade74