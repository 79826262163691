import affichearticle3 from "./cards/AFFICHE ARTICLE.png"

function MiddleSchool3() {
    return (<>
        <h1> Halloween Tangram </h1>
        <p> Hello everyone,</p>
        <p> Today, I'm sharing another Halloween-themed activity with you.</p>
        <p>Here is a folder with halloween tangrams !</p>
        <p>Help and correction are available.</p>
        <p>The file contains 8 tangrams with the correction and is FREE.</p>
        <img src={affichearticle3}/>
        <a href="/assets/MY%20HALLOWEEN%20TANGRAM%20.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool3