import affichearticle610 from "./cards/AFFICHE ARTICLE-41.jpg"

function Grade610() {
    return (<>
        <h1> Task Cards - Inequalities </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of inequalities with 6th grade students.</p>
        <img src={affichearticle610}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%206TH%20GRADE-Inequalities.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade610