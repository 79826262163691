import affichearticle from "./cards/AFFICHE ARTICLE-52.jpg"

function Grade73() {
    return (<>
        <h1> Task Cards - Inequalities And Equations </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of inequalities and equations with 7th grade students.</p>
        <img src={affichearticle}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%207TH%20GRADE%20INEQUALITIES%20AND%20EQUATIONS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade73