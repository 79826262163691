import affichearticle7 from "./cards/AFFICHE ARTICLE-3.jpg"

function MiddleSchool7() {
    return (<>
        <h1> Christmas Notebook </h1>
        <p> Hello everyone,</p>

        <p>Today, I'm sharing another Christmas-themed activity with you.</p>
        <p>Here is a folder with 12 type sets:</p>
        <p>CROSS GRIDS</p>
        <p>MAGIC SQUARES</p>
        <p>WHO IS THIS ?</p>
        <p>Correction are available.</p>
        <p>The file contains 12 games with the correction and is FREE.</p>
        <img src={affichearticle7}/>
        <a href="/assets/MY%20CHRISTMAS%20NOTEBOOK%20.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool7