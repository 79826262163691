import affichearticle19 from "./cards/AFFICHE ARTICLE-10.jpg"

function MiddleSchool19() {
    return (<>
        <h1> Fun Activities - Notebook </h1>
        <p> Hello everyone,</p>

        <p>I share with you a file of 54 logical activities (puzzles, sudoku, riddles). Students move forward in this folder independently when they have finished the day's work or if they are ahead of the rest of the group.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle19}/>
        <a href="/assets/MY%20FUN%20ACTIVITIES%20.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool19