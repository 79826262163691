import affichearticle1 from "./cards/AFFICHE ARTICLE-11.jpg"

function Digital1() {
    return (<>
        <h1> Scratch Notebook </h1>
        <p> Hello everyone,</p>

        <p>I share with you today, an activity of 5 games to do with your students. I find it important to approach the
            learning of code with students, especially in the digital age in which we live. The Scratch software is very
            easy for students to learn and allows you to do a lot of things.</p>
        <img src={affichearticle1}/>
        <a href="/assets/SCRATCH%20NOTEBOOK.pdf" download>Download the PDF !</a>
        <p> I also like to use this little book which offers great little discovery activities.</p>
        <a href="https://www.amazon.com/Official-Scratch-Coding-Cards-3-0/dp/1593279760/ref=sr_1_8?crid=1RN8Z8J3NUKOO&keywords=scratch+coding&qid=1674474170&sprefix=scratch+coding%2Caps%2C213&sr=8-8" target="_blank">Click Here !</a>
        <p> I also use the tutorial videos made by the "Scratch" team which are very concrete and easy to
            understand.</p>
        <a href="https://www.youtube.com/@ScratchTeam/videos" target="_blank">Click Here !</a>
    </>)
}

export default Digital1