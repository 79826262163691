import LayoutArticles from "./LayoutArticles";
import {React} from "react";

const TeacherPaper = () => {
    return (<>
        <h1>Teacher Paper Test</h1>
        <LayoutArticles section={'TeacherPaper'} subsection={'Home'}/>
        </>
    );
};
export default TeacherPaper;