import affichearticle16 from "./cards/AFFICHE ARTICLE-8.jpg"

function MiddleSchool16() {
    return (<>
        <h1> TASK CARDS - DESIGNS </h1>
        <p> Hello everyone,</p>

        <p>This week, i am sharing 11 blank templates for you to create your own task cards.</p>
        <p>The documents are in PDF format so you can complete it !</p>
        <p>The file is FREE.</p>
        <h1> WHY USE TASK CARDS ? </h1>
        <p>Task cards are tools that I love to use in the classroom because they allow me to meet the needs of my
            students.</p>
        <p>It is often a set of small cards working on the same concept where each small card asks for a question or an
            exercise. In general, these are questions or exercises that are done quickly. In addition, the answer key is
            very often provided. This allows students to self-correct and be independent !</p>

        <h1> HOW TO USE THIS TASK CARDS ? </h1>
        <p>In cooperative work : You can give one card at a time per team. All team members must agree before
            switching cards. This promotes the exchange of strategies between students !</p>

        <p>In individual work : Task cards are a good way to consolidate the concepts covered in class. You
            can give a set or a few cards to a student.</p>

        <p>In recovery : I can take a few cards to work on a certain concept with a student. So I don't
            need to find another hardware for recoveries.</p>

        <p>Pedagogical differentiation : A full set of task cards, between 30 cards, can seem overwhelming for
            struggling students. So you can ask them to do less. Also, personally, I build my series of task cards in
            difficulty level. Sometimes, for the cards that take longer to complete, I put the cards
            together by level of difficulty on different tables and let the students decide which level
            they want to work on.</p>

        <p>In my site, you will find free task sheets, on all the subjects covered in middle school in
            mathematics.</p>

        <img src={affichearticle16}/>
        <a href="/assets/TASK%20CARDS%20DESIGNS.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool16