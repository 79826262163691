import affichearticle5 from "./cards/AFFICHE ARTICLE-3.png"

function MiddleSchool5() {
    return (<>
        <h1> Christmas Sudoku </h1>
        <p> Hello everyone,</p>

        <p> Today, I'm sharing another Christmas-themed activity with you.</p>
        <p> Here is the folder with the Christmas Sudoku !</p>
        <p> To challenge your students with these 15 designs.</p>
        <p> Help and correction are available.</p>
        <p>The file contains 15 sudoku with the correction and is FREE.</p>
        <img src={affichearticle5}/>
        <a href="/assets/MY%20CHRISTMAS%20SUDOKU.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool5