import affichearticle2 from "./cards/AFFICHE ARTICLE-2.jpg"

function MiddleSchool2() {
    return (<>
        <h1> Halloween Sudoku </h1>
        <p> Hello everyone,</p>

        <p>Still not sure what to do as the fall holidays approach?</p>
        <p>Here is the folder with the Halloween Sudoku !</p>
        <p>To challenge your students with these 15 monsters.</p>
        <p>The file contains 15 sudoku with the correction and is FREE.</p>
        <img src={affichearticle2}/>
        <a href="/assets/MY%20HALLOWEEN%20SUDOKU.pdf" download>Download the PDF !</a>
    </>)
}
export default MiddleSchool2