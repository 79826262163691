import affichearticle15 from "./cards/AFFICHE ARTICLE-7.jpg"

function MiddleSchool15() {
    return (<>
        <h1> The Last Week - Day #5 </h1>
        <p> Hello everyone,</p>

        <p>This week, I'm sharing with you the 5 activities I do with my students during the last week of school.</p>
        <p>DAY #5 : Who is the guilty ?</p>
        <p>In this activity, students must solve puzzles to collect clues. In the end, they must piece together all the clues to find who stole Ryan's science project.</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle15}/>
        <a href="/assets/Investigation%20in%20Elfreth's%20Alley.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool15