import affichearticle69 from "./cards/AFFICHE ARTICLE-40.jpg"

function Grade69() {
    return (<>
        <h1> Task Cards - Whole Numbers </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of whole numbers with 6th grade students.</p>
        <img src={affichearticle69}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%206TH%20GRADE%20-%20WHOLE%20NUMBERS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade69