import affichearticle from "./cards/AFFICHE ARTICLE-61.jpg"

function Grade83() {
    return (<>
        <h1> Task Cards - Functions </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of function with 8th grade students.</p>
        <img src={affichearticle}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%208TH%20GRADE%20-%20FUNCTION.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade83