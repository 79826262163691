import affichearticle3 from "./cards/AFFICHE ARTICLE-7.png"

function Digital3() {
    return (<>
        <h1> The Halloween Investigation </h1>
        <p> Hello everyone,</p>

        <p>I share with you a digital activity that I created with the "Genially" software.
            Students must free Lauren from the haunted house and for this they will have to solve 5 puzzles.</p>
        <p>The game is interactive and provides clues to help students.</p>
        <img src={affichearticle3}/>
        <p>I'm also sharing the PDF that explains where the interactive areas and puzzle solutions are.</p>
        <a href="/assets/The%20halloween%20investigation.pdf" download>Download the PDF !</a>
        <p>Discover my creation !</p>
        <a href="https://view.genial.ly/6362523245acf300129eae00" target="_blank">Click Here !</a>
    </>)
}

export default Digital3