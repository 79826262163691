import affichearticle11 from "./cards/AFFICHE ARTICLE-8.png"

function MiddleSchool11() {
    return (<>
        <h1> The Last Week - Day #1 </h1>
        <p> Hello everyone,</p>

        <p>This week, I'm sharing with you the 5 activities I do with my students during the last week of school.</p>
        <p>DAY #1 : Geometry activity</p>
        <p>In this activity, students discover the golden ratio while having fun !</p>
        <p>Correction are available.</p>
        <p>The file is FREE.</p>
        <img src={affichearticle11}/>
        <a href="/assets/Geometry%20activity.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool11