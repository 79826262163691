import affichearticle2 from "./cards/AFFICHE ARTICLE-6.png"

function Digital2() {
    return (<>
        <h1> The Christmas Investigation </h1>
        <p> Hello everyone,</p>

        <p>I share with you a digital activity that I created with the "Genially" software.
            Students must find Santa Claus and for this they will have to solve 5 puzzles.</p>
        <p>The game is interactive and provides clues to help students.</p>
        <img src={affichearticle2}/>
        <p>I'm also sharing the PDF that explains where the interactive areas and puzzle solutions are.</p>
        <a href="/assets/The%20Christmas%20investigation.pdf" download>Download the PDF !</a>
        <p>Discover my creation !</p>
        <a href="https://view.genial.ly/63641499869f56001201ca45" target="_blank">Click Here !</a>
    </>)
}

export default Digital2