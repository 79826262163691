import affichearticle67 from "./cards/AFFICHE ARTICLE-71.jpg"
import affichearticle62 from "./cards/AFFICHE ARTICLE-65.jpg";

function Grade67() {
    return (<>
        <h1> Battle Whole Numbers </h1>
        <p> Hello everyone,</p>

        <p>I share with you a a game to review the whole numbers. This is a game that I give to students during the first week of class.</p>
        <p>The game includes game aids and rules.</p>
        <img src={affichearticle62}/>
        <p>I share the PDF.</p>
        <a href="/assets/BATTLE%20WHOLE%20NUMBERS%206TH%20GRADE.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade67