import affichearticle from "./cards/AFFICHE ARTICLE.jpg"

function MiddleSchool1() {
    return (<>
        <h1> Back To School </h1>
        <p> Hello everyone,</p>

        <p>To start the school year with my students, I use this little file of logic riddles.
            This allows them to think while having fun.</p>

        <p>In general, students are divided into teams and the group with the most correct answers wins the game.</p>

        <p>The file contains 9 riddles with the correction and is FREE.</p>
        <img src={affichearticle}/>
        <a href="/assets/BACK%20TO%20SCHOOL%20NOTEBOOK.pdf" download>Download the PDF !</a>
    </>)
}

export default MiddleSchool1