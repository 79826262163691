import affichearticle from "./cards/AFFICHE ARTICLE-58.jpg"

function Grade76() {
    return (<>
        <h1> Task Cards - Statistics And Probability </h1>
        <p> Hello everyone,</p>

        <p>I share with you a series of 30 task sheets to rework the notions of statistics and probability with 7th grade students.</p>
        <img src={affichearticle}/>
        <p>I'm also sharing the PDF. All you have to do is cut and laminate.</p>
        <a href="/assets/TASK%20CARDS%207TH%20GRADE%20STATISTICS.pdf" download>Download the PDF !</a>
    </>)
}

export default Grade76