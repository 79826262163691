import affichearticle21 from "./cards/AFFICHE ARTICLE-14.jpg"

function MiddleSchool21() {
    return (<>
        <h1> Geometric Material </h1>
        <p> Hello everyone,</p>

        <p>For me, the manipulation to fully understand the notions in geometry is essential. This is why I share with you the two materials that I use in class to study the notions of area, development, volume, type of figure</p>
        <img src={affichearticle21}/>
        <p>POLYDRON</p>
        <a href="https://www.amazon.com/Polydron-Frameworks-Platonic-Solids-Multicolored/dp/B071NW2YNY/ref=sr_1_9?crid=3BD5VRE50PSPN&keywords=polydron&qid=1685903973&sprefix=polydron%2Caps%2C226&sr=8-9" target="_blank">Click Here !</a>
        <p>GEOMETRIC SHAPES</p>
        <a href="https://www.amazon.com/Learning-Resources-Folding-Geometric-Shapes/dp/B0006PKZ54/ref=sr_1_3_sspa?crid=2O3ECHJDAP68V&keywords=solid+geometric+shapes&qid=1685904101&sprefix=solidn+geome%2Caps%2C212&sr=8-3-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzSVFGVUUyMjhIV0QmZW5jcnlwdGVkSWQ9QTA0ODY3MjkxNTk4M0pSREVQUlpXJmVuY3J5cHRlZEFkSWQ9QTA5MzY1ODgzREpGNlRYUEhRT0ZIJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==" target="_blank">Click Here !</a>
    </>)
}

export default MiddleSchool21